import React, { useState } from "react";
import { Box, TextField, Button, Typography, Card, CardContent } from "@mui/material";
import emailjs from "@emailjs/browser";

const GetInTouch: React.FC = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [formError, setFormError] = useState(false);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        const templateParams = {
            name,
            email,
            subject,
            message,
        };

        emailjs.send('service_0p788p7', 'template_u3i3qfi', templateParams, {
            publicKey: 'f3XefnwXcp0ESRLvN',
          })
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setFormSubmitted(true);
                setFormError(false);
                setName('');
                setEmail('');
                setSubject('');
                setMessage('');
            })
            .catch((error) => {
                console.log('FAILED...', error);
                setFormError(true);
            });
    };

    return (
        <Card sx={{ padding: "20px" }}>
            <CardContent>
                <Typography variant="h4" component="h2" align='center'>Get in Touch</Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Name"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                    <TextField
                        label="Email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <TextField
                        label="Subject"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        required
                    />
                    <TextField
                        label="Message"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        multiline
                        rows={4}
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        required
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                        <Button variant="contained" color="primary" type="submit">Submit</Button>
                    </Box>
                </form>
                {formSubmitted && !formError && (
                    <Typography variant="body1" color="success.main" align="center" marginTop="20px">
                        Thank you for getting in touch! I'll respond as soon as possible.
                    </Typography>
                )}
                {formError && (
                    <Typography variant="body1" color="error.main" align="center" marginTop="20px">
                        Oops! Something went wrong. Please try again later.
                    </Typography>
                )}
            </CardContent>
        </Card>
    );
};

export default GetInTouch;
