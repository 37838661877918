import React from "react";
import { AppBar, Toolbar, Box } from "@mui/material";
import LayoutWidthContainer from "../shared/LayoutWidthContainer";
import HideOnScroll from "./HideOnScroll";
import NavigationLinks from "./NavigationLinks";
import Logo from "./Logo";
//@ts-ignore
import logo from "/static/logo.svg.png"

const Navigation = (): JSX.Element => {
  return (
    <>
      <HideOnScroll>
        <AppBar color="secondary">
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <LayoutWidthContainer>
              <Toolbar
                sx={{ justifyContent: "space-between", height: 6 }}
                variant="dense"
              >
                 <Logo src={logo} width={100} height={28}/>
                <NavigationLinks />
              </Toolbar>
            </LayoutWidthContainer>
          </Box>
        </AppBar>
      </HideOnScroll>
    </>
  );
};

export default Navigation;
