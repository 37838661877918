import { useSiteMetadata } from "gatsby-theme-material/src/hooks/use-site-metadata";
import * as React from "react";

export const SEO = (): JSX.Element => {
  const { title, description, siteUrl } = useSiteMetadata();

  const seo = {
    title,
    description,
    image: 'https://juliaspivak.com/logo.png',
    url: `${siteUrl}`,
  };

  return (
    <>
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      
      {/* Open Graph tags */}
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content="website" />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />
      <meta name="twitter:url" content={seo.url} />
    </>
  );
};
