import React from 'react';

interface LogoProps {
  src: string;
  width?: number;
  height?: number;
}

const Logo: React.FC<LogoProps> = ({ src, width, height }) => {
  return <img src={src} width={width} height={height} />;;
}

export default Logo;
