import React, { useState, useEffect, useCallback } from 'react';
import { Card, CardContent, TextField, List, ListItem, CircularProgress, Typography, InputAdornment, IconButton, CardHeader } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';


const HomeValueSearch: React.FC = () => {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [propertyDetails, setPropertyDetails] = useState<any | null>(null);

    const fetchSuggestions = useCallback(async () => {
        if (query.length < 3) {
            setSuggestions([]);
            return;
        }

        const url = `https://zillow-com1.p.rapidapi.com/locationSuggestions?q=${query}`;
        const options = {
            method: 'GET',
            headers: {
                'x-rapidapi-key': process.env.GATSBY_RAPID_API_KEY || '',
                'x-rapidapi-host': 'zillow-com1.p.rapidapi.com'
            }
        };

        setLoading(true);
        setError(null);

        try {
            const response = await fetch(url, options);
            const { results } = await response.json();
            setSuggestions(results || []);
        } catch (error) {
            setError('Failed to fetch suggestions');
        } finally {
            setLoading(false);
        }
    }, [query]);

    useEffect(() => {
        const timeoutId = setTimeout(fetchSuggestions, 300);
        return () => clearTimeout(timeoutId);
    }, [query, fetchSuggestions]);

    const fetchPropertyDetails = async (zpid: string) => {
        if (zpid) {
            const url = `https://zillow-com1.p.rapidapi.com/property?zpid=${zpid}`;
            const options = {
                method: 'GET',
                headers: {
                    'x-rapidapi-key': process.env.GATSBY_RAPID_API_KEY || '',
                    'x-rapidapi-host': 'zillow-com1.p.rapidapi.com'
                }
            };

            try {
                const response = await fetch(url, options);
                const result = await response.json();
                setPropertyDetails(result);
            } catch (error) {
                console.error(error);
            };
        }
    };

    const handleSuggestionClick = (suggestion: any) => {
        setQuery(suggestion.display);
        setSuggestions([]);
        fetchPropertyDetails(suggestion.metaData.zpid);
    };

    const clearInput = () => {
        setQuery('');
        setSuggestions([]);
        setPropertyDetails(null);
    };

    const handleChange = (e: any) => {
        setQuery(e.target.value);
        if (query.length <= 1) {
            setSuggestions([]);
            setPropertyDetails(null);
        };
    };

    return (
        <Card>
            <CardContent>
                <Typography mb={2} variant="h4" component="h2" align='center'>What's your home worth?</Typography>
                <TextField
                    color='info'
                    label="Type your address here"
                    variant="outlined"
                    fullWidth
                    value={query}
                    onChange={handleChange}
                    sx={{ color: 'green' }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                {query ? (<IconButton onClick={clearInput} edge="end">
                                    <ClearIcon />
                                </IconButton>) : (<IconButton onClick={clearInput} edge="end"> <SearchIcon /></IconButton>)}
                            </InputAdornment>
                        ),
                    }}
                />
                {loading && <CircularProgress />}
                {error && <Typography color="error">{error}</Typography>}
                <List>
                    {!propertyDetails && Array.isArray(suggestions) && suggestions?.map((suggestion, index) => (
                        <ListItem key={index} onClick={() => handleSuggestionClick(suggestion)}>
                            {suggestion.display}
                        </ListItem>
                    ))}
                </List>
                {propertyDetails && (
                    <Card sx={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", padding: "15px" }}>
                        <Typography
                            sx={{
                                fontSize: "2rem",
                                fontWeight: "bold",
                                color: "#c23248",
                                textAlign: "center",
                                padding: "15px",
                            }}
                            variant="h5"
                        >
                            {propertyDetails.zestimate ? (
                                `≈ ${new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                }).format(propertyDetails.zestimate * 1.04)}`
                            ) : (propertyDetails.homeStatus === "FOR_SALE" || propertyDetails.homeStatus === "PENDING") && propertyDetails.price ? (
                                `Listed for sale at \n${new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 0,
                                }).format(propertyDetails.price)}`
                            ) : (
                                'Sorry, not enough data'
                            )}
                        </Typography>

                        {propertyDetails?.imgSrc && (
                            <img style={{
                                width: "75%",
                                height: "75%",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.7)"
                            }} src={propertyDetails.imgSrc} alt="Property Image Not Available" />)}
                    </Card>)}
            </CardContent>
        </Card>
    );
};


export default HomeValueSearch;
