import React from "react";
import { visuallyHidden } from "@mui/utils";
import { Typography } from "@mui/material";

import {
  useInfiniteFeed,
  FeedTemplateProps,
} from "gatsby-theme-advanced";

import Layout from "../../../layouts";
import FeedListing from "../../../components/FeedListing";
import ListingPageWrapper from "../../../components/shared/ListingPageWrapper";

const Feed = ({ pageContext }: FeedTemplateProps): JSX.Element => {
  const { feedListing, feedElementRef } = useInfiniteFeed(pageContext);


  // Don't show hero images on non-index feeds
  const noHero = pageContext.feedType !== "index";

  return (
    <Layout>
      <ListingPageWrapper ref={feedElementRef}>
        <Typography sx={visuallyHidden} variant="h1">
          Julia Spivak
        </Typography>
        <FeedListing listing={feedListing} noHero={noHero} />
      </ListingPageWrapper>
    </Layout>
  );
};

export default Feed;
