import React from "react";

import { StaticImage } from "gatsby-plugin-image";

import { Typography, Card, Box, Stack } from "@mui/material";

import { Types } from "gatsby-theme-advanced";

import ArticleCardSkeleton from "./Skeleton";

type ArticleHeroCardProps = {
  post?: Types.Post;
  hero?: boolean;
};

const ArticleCard = ({ post, hero }: ArticleHeroCardProps): JSX.Element => {
  // If no post is provided, render a skeleton view with a loading animation
  if (!post) return <ArticleCardSkeleton />;

  return (
    <Card
      sx={{
        display: "grid",
        gap: { zero: 1, lg: hero ? 4 : 1 },
        gridColumn: hero ? { lg: "span 2" } : {},
        gridTemplateColumns: hero ? { lg: "6fr 4fr" } : {},
      }}
    >
      <StaticImage src="../../images/julia-hero.jpg" alt="Julia Spivak"
        style={{
          width: "100%",
          height: "400px",
          borderRadius: "4px",
        }}
        imgStyle={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
          borderRadius: "4px",
        }}
        loading="eager"
        placeholder="blurred"
        layout="fullWidth" />
      <Stack
        justifyContent="space-around"
        alignItems="center"
        sx={{
          padding: { zero: 1, lg: hero ? 0 : 1 },
        }}
      >
        <Box sx={{pr: 1, pl: 1}}>
          <Typography sx={{ marginBottom: 1, textAlign: {lg: 'left', zero: 'center'}}} variant="h3" component="h2">
            Julia Spivak
          </Typography>
          <Typography sx={{ marginBottom: 2, textAlign: {lg: 'left', zero: 'center'}}} variant="h5" component="h3">
            Real Estate Advisor
          </Typography>
          <Typography>
            I'm a realtor with Engel & Volkers 30A Beaches respresenting current and future Emerald Coast homeowners, specializing in the luxury 30A market. My creative, open and caring nature helps matching each client's goals as well as skillfully featuring a home's unique presence and character.
          </Typography>
        </Box>
      </Stack>
    </Card>
  );
};

export default ArticleCard;
