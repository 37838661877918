import * as React from "react";

import { Box, Stack } from "@mui/material";

import { Types } from "gatsby-theme-advanced";

import ArticleCard from "../ArticleCard";
import LayoutWidthContainer from "../shared/LayoutWidthContainer";
import HomeValueSearch from "../HomeValueSearch";
import GetInTouch from "../Get in touch";
import styled from "@emotion/styled";

const GradientDiv = styled.div`
  background: linear-gradient(red, red) center center / 1px 100% no-repeat;
  height: 75px; 
  // display: none; /* Hide by default */
  
  @media (min-width: 1024px) {
    display: block; /* Show on desktop viewports */
`;

type PostListingProps = {
  listing: Types.FeedList;
  noHero?: boolean;
};

const FeedListing = ({ listing, noHero }: PostListingProps): JSX.Element => {
  return (
    <Stack
      component={LayoutWidthContainer}
      alignContent="space-between"
      justifyItems="stretch"
      spacing={{ lg: 3, zero: 3 }}
    >
      <Box
        sx={{
          width: "100%",

          display: "grid",
          gap: { zero: 8, lg: 10 },
          gridTemplateColumns: { zero: "1fr", lg: "1fr 1fr" },
        }}
      >
        {listing?.slice(0, 1).map((feedItem, idx) => {
          // Check if we're rendering a placeholder
          if ("isPlaceholder" in feedItem)
            return <ArticleCard key={feedItem.key} />;

          return idx === 0 && !noHero ? (
            <ArticleCard key={feedItem.slug} post={feedItem} hero  />
          ) : (
            <ArticleCard key={feedItem.slug} post={feedItem} />
          );
        })}
      </Box>
      <GradientDiv/>
      <HomeValueSearch/>
      <GradientDiv/>
      <GetInTouch/>
    </Stack>
  )
};

export default FeedListing;
