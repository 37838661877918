import React from "react";
import { Stack } from "@mui/material";

import { Types } from "gatsby-theme-advanced";

import FeedListing from "../FeedListing";

type RelatedPostsProps = {
  list: Types.PostList;
};

const RelatedPosts = ({ list }: RelatedPostsProps): JSX.Element => (
  <Stack
    component="aside"
    justifyContent="center"
    alignItems="center"
    spacing={5}
  >
    <FeedListing noHero listing={list} />
  </Stack>
);

export default RelatedPosts;
