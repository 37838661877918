import React from "react";

import { Stack } from "@mui/material";

import { Types, PostTemplateProps } from "gatsby-theme-advanced";
import { SEO } from "../../../seo/seo"

import Layout from "../../../layouts";
import RelatedPosts from "../../../components/RelatedPosts";

const PostTemplate = ({
  data,
  pageContext,
}: PostTemplateProps): JSX.Element => {

  return (
    <Layout>
      <Stack spacing={10}>
        <SEO/>
        <RelatedPosts
          list={pageContext.relatedPosts.map(Types.jsonPostIntoPost)}
        />
      </Stack>
    </Layout>
  );
};

export default PostTemplate;
